@import '~@angular/material/theming';

// Layout
$gutter: 16px !default;

// Sidenav
$sidenav-width: 350px !default;
$sidenav-collapsed-width: 50px !default;
$sidenav-width-mobile: 280px !default;

// Toolbar
$toolbar-height-desktop: 64px !default;
$toolbar-height-mobile: 56px !default;

// Topmenu
$topmenu-sticky-position-desktop: $toolbar-height-desktop !default;
$topmenu-sticky-position-mobile: $toolbar-height-mobile !default;

// Typography
$font-family-sans-serif: 'Roboto', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
  'Lucida Grande', sans-serif !default;
$font-family-monospace: 'Roboto Mono', monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  xsmall: 0,
  small: 600px,
  medium: 960px,
  large: 1280px,
  xlarge: 1920px,
) !default;

// Material colors
$mat-colors: (
  red: $mat-red,
  pink: $mat-pink,
  purple: $mat-purple,
  deep-purple: $mat-deep-purple,
  indigo: $mat-indigo,
  blue: $mat-blue,
  light-blue: $mat-light-blue,
  cyan: $mat-cyan,
  teal: $mat-teal,
  green: $mat-green,
  light-green: $mat-light-green,
  lime: $mat-lime,
  yellow: $mat-yellow,
  amber: $mat-amber,
  orange: $mat-orange,
  deep-orange: $mat-deep-orange,
  brown: $mat-brown,
  gray: $mat-gray,
  blue-gray: $mat-blue-gray,
  white: white,
  black: black,
  light: $light-primary-text,
  dark: $dark-primary-text,
) !default;
