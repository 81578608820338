/** Override Material styles */

.mat-card {
  margin-bottom: $gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: $gutter;
}

.mat-list-base {
  position: relative;
}

.mat-checkbox,
.mat-radio-button,
.mat-slide-toggle {
  margin-right: $gutter * .5;

  [dir='rtl'] & {
    margin-right: auto;
    margin-left: $gutter * .5;
  }
}

.form-field-full {
  .mat-form-field {
    width: 100%;
  }
}
.mtx-dialog-content {
  white-space: pre-line;
}
button.mat-button-base.mtx-dialog-button-custom {
  margin-right: 10px;
}
