// Icon

.icon-20 {
  @include icon(20);
}

.icon-24 {
  @include icon(24);
}

.icon-36 {
  @include icon(36);
}

.icon-48 {
  @include icon(48);
}
