@import '~ngx-toastr/toastr';

.toast-container {
  .ngx-toastr {
    min-height: 48px;
    padding: 20px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.7);
    background-color: #323232;
    line-height: 1.5;

    @include mat-elevation(6);

    &:hover {
      @include mat-elevation(10);
    }
  }

  .toast-info,
  .toast-warning,
  .toast-error {
    padding-left: 50px;
    color: #fff;
  }

  .toast-success {
    color: var(--gray-dark);
  }

  .toast-success {
    padding-left: 30px;
    background-image: none !important;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .toast-success:before {
    content: '\2713';
    color: var(--primary);
    transform: scale(2.5);
  }

  .toast-info {
    background-color: mat-color($mat-blue, 500);
  }

  .toast-success {
    background-color: var(--white);
  }

  .toast-warning {
    background-color: mat-color($mat-orange, 500);
  }

  .toast-error {
    background-color: mat-color($mat-red, 500);
  }

  .toast-half-width {
    max-width: 45em;
  }

  .toast-close-button {
    font-size: inherit;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);

    &:hover {
      color: inherit;
      opacity: 0.6;
    }
  }
}
